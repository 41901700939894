import React, { useState } from "react";
import "./AudioList.css";

const ExperienceList = () => {
  const initialExperiences = [
    {
      experience: "Work",
      name: "ML Engineer at RBC Borealis (formerly Borealis AI)",
      description:
        "Machine learning engineering for home equity finance.",
      date: "June 2024 – ",
    },
    {
      experience: "Work",
      name: "MLE Intern at RBC Borealis (formerly Borealis AI)",
      description:
        "Built data orchestration tools and pipelines for researchers.",
      date: "May 2023 – August 2023",
    },
    {
      experience: "Work",
      name: "Full-stack Intern at Limelight Software",
      description:
        "Cloud FP&A software in Java and React.",
      date: "May 2021 – July 2022",
    },
    {
      experience: "Project",
      name: "MobileOgel",
      description:
        "An iOS app that uses computer vision to find you Lego sets you can build with your spare pieces.",
      date: "September 2023 – April 2024",
    },
    {
      experience: "Project",
      name: "Real-time Elevator Sim.",
      description:
        "A real-time Java-based elevator control simulator using multi-threading and UDP.",
        date: "January 2023 – April 2023",
    },
    {
      experience: "Project",
      name: "Sign Me Up",
      description:
        "Duolingo for American Sign Language!",
      date: "May 2022 – July 2022",
    },
  ];

  const [experiences, setExperiences] = useState(initialExperiences);
  const [showDesc, setShowDesc] = useState(
    experiences.reduce((acc, exp) => {
      acc[exp.name] = false;
      return acc;
    }, {})
  );
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const toggleDescription = (name) => {
    setShowDesc((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedExperiences = [...experiences].sort((a, b) => {
      if (key === "date") {
        // Extract and parse the start date from the date string
        const parseDate = (dateString) => {
          const startDateStr = dateString.split("–")[0].trim();
          return new Date(startDateStr + " 1"); // Add a day for parsing (e.g., "June 2024" -> "June 1, 2024")
        };
        const dateA = parseDate(a.date);
        const dateB = parseDate(b.date);

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    setExperiences(sortedExperiences);
  };

  return (
    <table className="projects-table">
      <thead>
        <tr>
          <th className="header-cell" onClick={() => handleSort("experience")}>
            Experience {sortConfig.key === "experience" && (sortConfig.direction === "asc" ? "▲" : "▼")}
          </th>
          <th className="header-cell" onClick={() => handleSort("name")}>
            Name {sortConfig.key === "name" && (sortConfig.direction === "asc" ? "▲" : "▼")}
          </th>
          <th className="header-cell" onClick={() => handleSort("date")}>
            Date {sortConfig.key === "date" && (sortConfig.direction === "asc" ? "▲" : "▼")}
          </th>
        </tr>
      </thead>
      <tbody>
        {experiences.map((ex, index) => (
          <React.Fragment key={index}>
            <tr
              onClick={() => toggleDescription(ex.name)}
              className={
                showDesc[ex.name] ? "main-row row-expanded" : "main-row"
              }
            >
              <td
                className={
                  showDesc[ex.name] ? "exp-type row-expanded" : "exp-type"
                }
              >
                {ex.experience}
              </td>
              <td className={showDesc[ex.name] ? "row-expanded" : ""}>
                {ex.name}
              </td>
              <td className={showDesc[ex.name] ? "row-expanded" : ""}>
                {ex.date}
              </td>
            </tr>
            {showDesc[ex.name] && (
              <tr className="description-row">
                <td colSpan={3} className="description">
                  <p>{ex.description}</p>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default ExperienceList;
