import React from "react";
import "./AudioList.css";
import house from "../sounds/house-sept15-24.wav";
import nta from "../sounds/04-11-24.wav";
import WaveForm from "./Waveform";

const AudioList = () => {
  const songs = [
    { name: "13-09-24.wav", file: house },
    { name: "11-04-24.wav", file: nta },
  ];
  return (
    <table className="projects-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Waveform</th>
        </tr>
      </thead>
      <tbody>
        {songs.map((song) => (
          <tr key={song.id}>
            <td className="song-name">{song.name}</td>
            <td className="waveform-cell">
              <WaveForm file={song.file}></WaveForm>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AudioList;
