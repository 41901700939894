import React, { useState } from "react";
import "./Home.css";
import AudioList from "../Components/AudioList";
import ExperienceList from "../Components/ExperienceList";

const Home = () => {
  const [devMode, setDevMode] = useState(true);
  const [buttonState, setButtonState] = useState({
    dev: "Dev ↓",
    music: "Music",
  });

  const devClick = () => {
    setButtonState({ dev: "Dev ↓", music: "Music" });
    setDevMode(true);
  };

  const musicClick = () => {
    setButtonState({ dev: "Dev", music: "Music ↓" });
    setDevMode(false);
  };

  return (
    <div className="container">
      <div className="header-container">
        <header>
          <h1>Hi, I'm Guy Morgenshtern</h1>
          <p>
            Hope you're having a good day!
            <br />
          </p>
          <button
            className={devMode ? "dev selected" : "dev"}
            onClick={devClick}
          >
            {buttonState.dev}
          </button>
          <button
            className={!devMode ? "music selected" : "music"}
            onClick={musicClick}
          >
            {buttonState.music}
          </button>
        </header>
      </div>
      {/* Sliding content */}
      <div className="list-container">{devMode ? <ExperienceList /> : <AudioList />}</div>
    </div>
  );
};

export default Home;
